<template>
  <el-dialog title="上传文件"
             :close-on-click-modal="false"
             :visible.sync="upFileDialog"
             v-if="upFileDialog"
             width="40%"
             @close="handleCancle"
             append-to-body>
        <el-upload
            class="upload-demo"
            action="void"
            accept=".xml"
            :headers="config"
            ref="upload"
            :auto-upload="false"
            :on-change="changeHanle"
            :on-remove="handleRemove"
            multiple
            :file-list="fileLists"
            :with-credentials="true">
          <el-button slot="trigger" size="small" type="primary">选取文件</el-button>
          <el-button style="margin-left: 10px;" size="small" type="success" @click="submitFiles">上传到服务器</el-button>
          <el-button style="margin-left: 10px;" size="small" type="success"
                     :disabled="analysisEnable==='0'||analysisEnable===0" @click="analysFiles">进行解析</el-button>
          <div slot="tip" class="el-upload__tip">只能上传xml文件</div>
          <div slot="tip" class="el-upload__tip">上传后同名文件会相互覆盖,以上传列表中的最后一个为准</div>
          <div slot="tip" class="el-upload__tip">需要文件上传成功才能解析文件</div>
        </el-upload>
      <span slot="footer" class="dialog-footer">
       <el-button @click="handleCancle">取 消</el-button>
      </span>
  </el-dialog>
</template>

<script>
import {getUUID} from "@/utils";

export default {
  name: "upload-file",
  data(){
    return{
      fileLists: [],
      upFileDialog: false,
      configType: {},
      uploadParams: undefined,
      analysisEnable: '0',
    }
  },
  created() {
    this.configType = {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    }
  },
  computed:{
    config(){
      return {
        token: this.getToken(),
      }
    }
  },
  methods:{
    getToken() {
      let ca = document.cookie.split(';');
      for (let i = 0; i < ca.length; i++) {
        let c = ca[i];
        while (c.charAt(0) == ' ') c = c.substring(1);
        if (c.indexOf("token=") != -1){
          return c.substring(6, c.length);
        }
      }
      return "";
    },
    init(){
      this.upFileDialog=true
    },
    //暂存文件
    changeHanle(file, fileList) {
      //filesList会在后续的处理汇中被改变，但是其中的数据确实需要的，因此复制一份
      this.analysisList=Object.assign({},fileList);
      let files = Array.from(fileList);
      let formData = new FormData();
      files.forEach((file, index) => {
        if (file.size / (1024 * 1024) > 100) {
          this.$message({
            message: file.name + "文件大小过大,无法上传",
            type: "error",
          });
          fileList.splice(index, 1);
        } else {
          formData.append("files", file.raw);
        }
      });
      this.uploadParams = formData;
      this.analysisEnable='0';
      this.fileLists=fileList
    },
    /**     移除上传文件    **/
    handleRemove(file,fileList) {
      //filesList会在后续的处理汇中被改变，但是其中的数据确实需要的，因此复制一份
      this.analysisList=Object.assign({},fileList);
      if(fileList.length===0){
        this.uploadParams=undefined;
        return
      }
      this.$refs.upload.abort(); //取消上传
      let files = Array.from(fileList);
      let formData = new FormData();
      files.forEach((file, index) => {
        if (file.size / (1024 * 1024) > 100) {
          this.$message({
            message: file.name + "文件大小过大,无法上传",
            type: "error",
          });
          fileList.splice(index, 1);
        } else {
          formData.append("files", file.raw);
        }
      });
      this.uploadParams = formData;
      this.$message({ message: "成功移除" + file.name, type: "success" });
      this.analysisEnable='0';
      this.fileLists=fileList
    },

    submitFiles() {
      if(this.uploadParams === undefined || this.uploadParams.length===0){
        this.$message({ message: "请先选择文件", type: "info" });
        return
      }
      //将文件上传到minio中
      this.postFilesRequest("/levelrelation/uploadFile", this.uploadParams)
          .then(({ data }) => {
              this.analysisEnable=data.analysis
          })
    },

    handleCancle() {
      this.upFileDialog=false
      this.uploadParams=undefined
    },
    analysFiles(){
      let nameLists=[];
      for(let i=0;i<this.fileLists.length;i++){
          nameLists.push(this.fileLists[i].name)
      }
      this.postRequest("/levelrelation/analysisFiles",nameLists).then((resp)=>{
        console.log(resp.data)
      })
    }
  }
}
</script>

<style scoped>

</style>